import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const authenticateUrl = process.env.REACT_APP_HOST_API + "/api/admin/authenticate";
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userProfile, setUserProfile] = useState(null);

  const fetchUser = async () => {
    if (token) {
      try {
        const response = await axios.get(authenticateUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.success) {
          setUserProfile(response.data.profile);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        userProfile,
        setUserProfile,
        fetchUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
// HomePage.js
import { Card, Button, Modal, Container, Row, Col, ListGroup, CardTitle, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { jsPDF } from "jspdf";
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/authContext";
import axios from "axios";
import './RecipePage.css';
import { useGroups } from "../context/groupContext";

const url = process.env.REACT_APP_HOST_API;

function RecipePage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [recipesPerPage] = useState(4);
  const indexOfLastRecipe = currentPage * recipesPerPage;
  const indexOfFirstRecipe = indexOfLastRecipe - recipesPerPage;

  const nextPage = () => setCurrentPage(prevPage => prevPage + 1);
  const prevPage = () => setCurrentPage(prevPage => prevPage - 1);


  // const { userProfile, setUserProfile } = useAuth();

  const { userProfile, setUserProfile } = useAuth();
  const [ingred, setIngred] = useState([]);
  const [show, setShow] = useState(false);
  const [showGrocery, setShowGrocery] = useState(false);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [groceryList, setGroceryList] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const currentRecipes = recipes.slice(indexOfFirstRecipe, indexOfLastRecipe);
  const [savedRecipes, setSavedRecipes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { groups } = useGroups();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [recipeToGroup, setRecipeToGroup] = useState(null);
  // const [maxRecipes, setMaxRecipes] = useState([]);
  // const [minRecipes, setMinRecipes] = useState([]);
  const currentDate = new Date(); // Creates a new date object representing the current time
  const formattedDate = currentDate.toISOString().slice(0, 19).replace('T', ' ');

  const handleShowAddGroup = (recipe) => {
    setRecipeToGroup(recipe);
    setShowAddGroup(true);
  };
  const handleCloseAddGroup = () => {
    setShowAddGroup(false);
  };
  const handleSelectGroup = (event) => {
    const groupId = event.target.value;
    const group = groups.find(g => g.group_id.toString() === groupId);
    setSelectedGroup(group || null);
  };

  const handleAddGroup = async () => {

    const data = { group_id: selectedGroup.group_id, recipe_id: recipeToGroup.recipe_id };

    try {
      await axios.post(`${url}/addRecipegroup`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      // Additional success handling, if needed
      console.log("Recipe added successfully");
      setShowAddGroup(false);
    } catch (error) {
      console.error('Error adding recipe: ', error);
    }
  };
  const handleClose = () => setShow(false);
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${url}/search`, { searchQuery, selectedFilter: 'Recipes' });
      setRecipes(response.data.results);
    } catch (error) {
      console.error('Error searching recipes:', error);
    }
  };

  const retrieveSavedRecipes = async () => {
    if (!userProfile) {
      console.log('UserProfile is null, skipping fetch.');
      return;
    }

    try {
      const response = await axios.post(`${url}/getRecipeUser`, { user_id: userProfile.user_id }, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = response.data;
      if (data.results && data.results.length > 0) {
        setSavedRecipes(data.results);
      } else {
        console.log('No recipes found for this user.');
        setSavedRecipes([]);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const handleShow = async (recipe) => {
    setSelectedRecipe(recipe);
    try {
      const response = await axios.post(`${url}/findIngredient`, { recipe_id: recipe.recipe_id }, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = response.data;
      setIngred(data.results); // Assuming this sets your ingredients
      console.log(ingred);
      setShow(true); // Only show the modal after everything is set
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };


  const handleRemoveRecipe = async (recipe) => {
    const isConfirmed = window.confirm("Are you sure you want to delete?");
    if (isConfirmed) {
      try {
        const response = await axios.post(`${url}/deleteRecipeFromUser`, { user_id: userProfile.user_id, recipe_id: recipe.recipe_id }, {
          headers: {
            "Content-Type": "application/json",
          }
        });
        retrieveSavedRecipes(); // Refresh the list
      } catch (error) {
        console.error("There has been a problem with your operation:", error);
      }
    }
  };
  const getIngredientList = async () => {
    const fetchPromises = savedRecipes.map(recipe =>
      axios.post(`${url}/findIngredient`, { recipe_id: recipe.recipe_id }, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(response => {
        return response.data.results.map(ingredient => ({
          food_name: ingredient.food_name,
          quantity: ingredient.quantity,
          from_recipe: recipe.recipe_name
        }));
      }).catch(error => {
        console.error('Error fetching data:', error);
        return []; // Return an empty array in case of error
      })
    );

    const allResults = await Promise.all(fetchPromises);
    const allIngredients = new Set();

    allResults.forEach(ingredients => {
      ingredients.forEach(ingredient => {
        allIngredients.add(ingredient.food_name.toLowerCase());
      });
    });

    return allIngredients;
  };

  const handleGroceryList = async () => {
    const ingredients = await getIngredientList();
    const ingredientsArray = Array.from(ingredients);
    // console.log(ingredientsArray);
    // console.log(ingredients);
    // console.log(ingredients.size);
    if (ingredientsArray && ingredientsArray.length > 0) {
      setGroceryList(ingredientsArray);
      console.log(groceryList)
      setShowGrocery(true);
    }

  }
  const exportToPDF = () => {
    const doc = new jsPDF();

    const leftColumnX = 10; // X position for left column
    const rightColumnX = 105; // X position for right column, roughly the middle of a standard page
    let y = 20; // Initial y position to start writing text
    const lineHeight = 10; // Height of each line of text
    const pageHeight = doc.internal.pageSize.height; // Get the height of the page
    let currentColumn = 'left';

    doc.setFontSize(12);
    doc.text('Grocery List', 10, 15);

    groceryList.forEach((item, index) => {
      if (y > pageHeight - 20) { // Check if the next line would go off the page
        doc.addPage(); // Add a new page
        y = 20; // Reset y position to top of the new page
        currentColumn = 'left'; // Reset to left column at new page
      }

      // Determine the x position based on the current column
      const x = currentColumn === 'left' ? leftColumnX : rightColumnX;

      doc.text(`- ${item}`, x, y); // Output text at correct x and y position

      // Determine which column to use next
      if (currentColumn === 'left') {
        currentColumn = 'right'; // Switch to right column
      } else {
        y += lineHeight; // Only increment y after filling both columns
        currentColumn = 'left'; // Reset to left column
      }
    });


    doc.save('grocery-list.pdf');
  };
  const handleCloseGroceryModal = () => setShowGrocery(false);
  const handleAddRecipe = async (recipe) => {
    setSavedRecipes(prev => [...prev, recipe]); // Update state first
    const data = { user_id: userProfile.user_id, recipe_id: recipe.recipe_id, date: formattedDate };

    try {
      await axios.post(`${url}/addRecipeUser`, data, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      // Additional success handling, if needed
      console.log("Recipe added successfully");
    } catch (error) {
      console.error('Error adding recipe: ', error);
    }
  };
  useEffect(() => {
    retrieveSavedRecipes()
  }, [userProfile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${url}/getAllRecipes`, {
          headers: {
            "Content-Type": "application/json",
          }
        });
        setRecipes(response.data.results);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [userProfile]);
  return (
    <>
      <div className='container'>
        {userProfile ? (
          <Container >
            <Card className="group_card h-100" style={{ zIndex: 1 }}> {/* Use h-100 to ensure the card takes full height */}
              <Card.Header as="h3" className="group_title">
                Your Selected Recipes
              </Card.Header>
              <Card.Body className="d-flex align-items-center justify-content-center">
                <Row className="w-100"> {/* Ensure the row takes full width of the card */}
                  <Col>
                    <ListGroup className="w-100">
                      {!!savedRecipes && savedRecipes.map((recipe) => (
                        <ListGroup.Item key={recipe.recipe_id} className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <img
                              src={recipe.image}
                              alt={recipe.recipe_name}
                              className="img-fluid rounded me-2"
                              style={{ width: '50px', height: '50px' }}
                            />
                            <div>{recipe.recipe_name}</div>
                          </div>
                          <div className="d-flex align-items-center">
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => handleShowAddGroup(recipe)}  // Assuming you have a handler for viewing details
                              className="me-2"  // Add margin to the right of the first button for spacing
                            >
                              Add To Group
                            </Button>
                            <Button
                              variant="outline-info"
                              size="sm"
                              onClick={() => handleShow(recipe)}  // Assuming you have a handler for viewing details
                              className="me-2"  // Add margin to the right of the first button for spacing
                            >
                              View Details
                            </Button>

                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() => handleRemoveRecipe(recipe)}
                            >
                              Remove
                            </Button>
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    <div className="d-flex justify-content-end mt-3 me-3">
                      <button type="button" className="btn btn-outline-primary btn-l" disabled={savedRecipes.length === 0} onClick={handleGroceryList}>
                        Build Grocery List
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

          </Container>
        ) : null}

      </div>
      <div className='container'>
        <div className='row my-4'>
          <Form onSubmit={handleSearch} className="mb-3">
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Search recipes..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Col>
              <Col xs="auto">
                <Button variant="primary" type="submit">Search</Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="d-flex justify-content-end mb-2">
          <Button variant="secondary" onClick={prevPage} disabled={currentPage === 1} className="rounded-circle me-2" style={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="bi bi-arrow-left"></i>
          </Button>
          <Button variant="secondary" onClick={nextPage} disabled={currentPage === Math.ceil(recipes.length / recipesPerPage)} className="rounded-circle" style={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="bi bi-arrow-right"></i>
          </Button>
        </div>

        <div className="row">
          {currentRecipes.map(recipe => (
            <div key={recipe.recipe_id} className="col-md-3 mb-3" onClick={() => handleShow(recipe)}>
              <Card style={{ height: '250px', position: 'relative' }}>

                <Card.Img variant="top" src={recipe.image} alt={recipe.recipe_name} style={{ maxHeight: '200px', objectFit: 'cover' }} />
                <Card.Body>
                  <Card.Title className="text-sm">{recipe.recipe_name}</Card.Title>

                  <Button
                    variant="primary"
                    disabled={!!savedRecipes && savedRecipes.some(savedRecipe => savedRecipe.recipe_id === recipe.recipe_id)} // Disable button if recipe is in savedRecipes
                    style={{ position: 'absolute', top: '0px', right: '0px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddRecipe(recipe);
                    }}
                  >
                    +
                  </Button>

                </Card.Body>
              </Card>
            </div>
          ))}

        </div>

      </div>


      {selectedRecipe && show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedRecipe.recipe_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Ingredients:</div>
            <ul>
              {ingred.map((ingredient, index) => (
                <li key={index}>{ingredient.quantity} of {ingredient.food_name}</li>
              ))}
            </ul>
            <div>Instructions:</div>
            <ol>
              {selectedRecipe.instruction.split('.').map((step, index) =>
                step.trim() ? <li key={index}>{step.trim()}</li> : null
              )}
            </ol>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {showGrocery && (
        <Modal show={showGrocery} onHide={handleCloseGroceryModal}>
          <Modal.Header closeButton>
            <Modal.Title>Grocery List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {groceryList.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={exportToPDF}>
              Export to PDF
            </Button>
            <Button variant="secondary" onClick={handleCloseGroceryModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )
      }
      {showAddGroup && (
        <Modal show={showAddGroup} onHide={handleCloseAddGroup}>
          <Modal.Header closeButton>
            <Modal.Title>Add Recipe to Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="groupSelect">
              <Form.Label>Select Group</Form.Label>
              <Form.Control as="select" onChange={handleSelectGroup} value={selectedGroup?.group_id || ''}>
                <option value="">Select a group...</option>
                {groups.map(group => (
                  <option key={group.group_id} value={group.group_id}>
                    {group.group_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddGroup}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddGroup}>
              Add to Selected Group
            </Button>
          </Modal.Footer>
        </Modal>
      )}

    </>
  );
};

export default RecipePage;

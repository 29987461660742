import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { useAuth } from "../context/authContext";
import axios from "axios";

const loginUrl = process.env.REACT_APP_HOST_API + "/api/admin/login";

function Login({ show, handleClose }) {
	const [credentials, setCredentials] = useState({
		username: "",
		password: "",
	});
	const [errorMessage, setErrorMessage] = useState("");
	const { setToken } = useAuth();
	const navigate = useNavigate();

	const handleChange = (e) => {
		const { name, value } = e.target;
		setCredentials((prevState) => ({
			...prevState,
			[name]: value,
		}));
		setErrorMessage("");
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post(loginUrl, credentials);
			const data = response.data;
			if (data.success) {
				setToken(data.token);
				localStorage.setItem("token", data.token);
				handleClose();
			} else {
				setErrorMessage(response.data.message || "Incorrect credentials.");
			}
			setCredentials({ username: "", password: "" });
		} catch (error) {
			setErrorMessage(error.response?.data?.message || "An error occurred.");
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Login</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="username">
						<Form.Label>Username</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter Username"
							value={credentials.username}
							onChange={handleChange}
							name="username"
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="password">
						<Form.Label>Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Enter Password"
							value={credentials.password}
							onChange={handleChange}
							name="password"
						/>
					</Form.Group>
					{errorMessage && (
						<div className="alert alert-danger" role="alert">
							{errorMessage}
						</div>
					)}
					<Button variant="primary" type="submit">
						Submit
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default Login;

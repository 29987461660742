import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAuth } from "../context/authContext";
import { useGroups } from "../context/groupContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";


const url = process.env.REACT_APP_HOST_API;


function GroupsPage() {
	const navigate = useNavigate();
	const { userProfile, fetchUser } = useAuth();
	const { groups } = useGroups();
	const [showModal, setShowModal] = useState(false);
	const [newGroup, setNewGroup] = useState({
		group_pass: "",
		group_name: "",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewGroup({ ...newGroup, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post(
				`${url}/add-group/${userProfile.user_id}`,
				{
					group_name: newGroup.group_name,
					group_pass: newGroup.group_pass,
				}
			);

			if (response.data.success) {
				fetchUser();
				setNewGroup({ group_pass: "", group_name: "" });
				setShowModal(false);
			}
		} catch (error) {
			console.error("Failed to add the group:", error);
		}
	};

	const handleGoIntoGroup = (group) => {
		console.log(`Entering ${group.group_name} group...`);
    	navigate(`/groups/${group.group_id}`);
	};

	const handleCloseModal = () => {
		setNewGroup({ group_pass: "", group_name: "" });
		setShowModal(false);
	};

	return (
		<div className="container mt-4">
			<Button variant="primary" onClick={() => setShowModal(true)}>
				Add Group
			</Button>
			<Modal
				show={showModal}
				onHide={handleCloseModal}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Add Group</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit}>
						<Form.Group>
							<Form.Label>Group Name:</Form.Label>
							<Form.Control
								type="text"
								name="group_name"
								value={newGroup.group_name}
								onChange={handleInputChange}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Group Password:</Form.Label>
							<Form.Control
								type="password"
								name="group_pass"
								value={newGroup.group_pass}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						Close
					</Button>
					<Button variant="primary" type="submit" onClick={handleSubmit}>
						Add
					</Button>
				</Modal.Footer>
			</Modal>
			<ul className="list-group mt-3">
				{groups &&
					groups.map((group) => (
						<li
							className="list-group-item d-flex justify-content-between align-items-center"
							key={group.group_id}
						>
							<span style={{ textTransform: "capitalize" }}>
								{group.group_name}
							</span>
							<button
								className="btn btn-primary btn-sm"
								onClick={() => handleGoIntoGroup(group)}
							>
								Go into Group
							</button>
						</li>
					))}
			</ul>
		</div>
	);
}

export default GroupsPage;

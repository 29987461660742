import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
import { GroupProvider } from "./context/groupContext";
import NavigationBar from "./components/NavigationBar";
import { PrivateRouteAdmin, PrivateLayout } from "./components/PrivateRoute";
import HomePage from "./pages/HomePage";
import UserHomePage from "./pages/UserHomePage";
import AdminPage from "./pages/AdminPage";
import GroupsPage from "./pages/GroupsPage";
import SettingPage from "./pages/SettingPage";
import RecipePage from "./pages/RecipePage";
import IndivGroupPage from "./pages/IndivGroupPage";

import FacilityReportPage from "./pages/FacilityReportAdmin";
function App() {
    return (
        <AuthProvider>
            <GroupProvider>
                <BrowserRouter>
                    <NavigationBar />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/home" element={<Navigate replace to="/" />} />
						<Route path="/userhome" element={<UserHomePage/>} />
                        {/* Nest private routes within PrivateLayout for consistent layout application */}
                        <Route element={<PrivateLayout />}>
                            <Route path="/admin" element={
                                <PrivateRouteAdmin>
                                    <AdminPage />
                                </PrivateRouteAdmin>
                            } />
                            <Route path="/groups" element={<GroupsPage />} />
                            <Route path="/recipes" element={<RecipePage />} />
                            <Route path="/groups/:groupId" element={<IndivGroupPage />} />
                            <Route path="/setting" element={<SettingPage />} />
							<Route path="/report" element={<FacilityReportPage />} />
							
                        </Route>
                    </Routes>
                </BrowserRouter>
            </GroupProvider>
        </AuthProvider>
    );
}

export default App;

import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../context/authContext';

const adminUrl = process.env.REACT_APP_HOST_API + "/api/admin";

function ChangePassword({ show, handleClose }) {
    const { token } = useAuth();
    const [credentials, setCredentials] = useState({
        currentPassword: "",
        newPassword: "",
        reenterPassword: "",
    });
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrorMessage("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(adminUrl + "/changePassword", credentials, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.success) {
                handleClose();
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || "An error occurred.");
        }
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="currentPassword">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter Current Password"
                            name="currentPassword"
                            value={credentials.currentPassword}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter New Password"
                            name="newPassword"
                            value={credentials.newPassword}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="reenterPassword">
                        <Form.Label>Reenter New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Reenter New Password"
                            name="reenterPassword"
                            value={credentials.reenterPassword}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ChangePassword;

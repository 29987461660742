import { Card, Button, Container, ListGroup, Row, Col, CardTitle, Form, Tab, Tabs } from 'react-bootstrap';
import { Link, Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { useGroups } from "../context/groupContext";
import { useAuth } from "../context/authContext";
import './UserHomePage.css';
import axios from 'axios'

const url = process.env.REACT_APP_HOST_API;
function UserHomePage() {


  const { userProfile, setUserProfile } = useAuth();
  const [key, setKey] = useState('totalRecipes');
  const [personalRecipes, setPersonalRecipes] = useState([]);
  const [avgIngred, setAvgIngred] = useState(0);
  const [maxRecipes, setMaxRecipes] = useState([]);
  const [minRecipes, setMinRecipes] = useState([]);
  const [sumRecipes, setSumRecipes] = useState(0);
  const [dailyRecipes, setDailyRecipes] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [chartOptions, setChartOptions] = useState({});
  const { groups } = useGroups();
  useEffect(() => {
    if (key === 'mostPopular') {
      retrieveMaxRecipes();
    }
    if (key === 'leastPopular') {
      retrieveMinRecipes();
    }
    if (key === 'activityGraph') {
      retrieveDailyRecipes();
    }
    if (key === 'average') {
      retrieveAvgIngred();
    }
    if (key === 'totalRecipes') {
      retrieveRecipesSum();
    }
  }, [key, userProfile]);
  useEffect(() => {
    if (userProfile) { // Ensuring userProfile is not null
      retrieveSavedRecipes(); // Function that fetches the recipes
    }
  }, []);

  const retrieveRecipesSum = async () => {
    try {
      const response = await axios.post(`${url}/getRecipesSum`, { user_id: userProfile.user_id }, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = response.data;
      if (data.results) {
        setSumRecipes(data.results[0]);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const retrieveDailyRecipes = async () => {
    try {
      const response = await axios.post(`${url}/getDailyRecipes`, { user_id: userProfile.user_id }, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = response.data;
      if (data.results) {
        setDailyRecipes(data.results);
        const labels = data.results.map(item => item.date.split('T')[0]);
        const num = data.results.map(item => item.added_recipes);

        setChartData({
          labels: labels,
          datasets: [
            {
              label: 'Recipes Added Per Day',
              data: num,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }
          ],

        });
        setChartOptions({
          scales: {
            x: {
              ticks: {
                maxRotation: 45,  // Rotate labels to 90 degrees
                minRotation: 45
              },
              title: {
                display: true,
                text: ''  // Label for the x-axis
              }
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Number of Recipes Added'  // Label for the y-axis
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            }
          },
          responsive: false,
          maintainAspectRatio: false
        });



      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const retrieveMaxRecipes = async () => {
    try {
      const response = await axios.post(`${url}/getMaxRecipes`, {}, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = response.data;
      if (data.results) {
        setMaxRecipes(data.results);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const retrieveMinRecipes = async () => {
    try {
      const response = await axios.post(`${url}/getMinRecipes`, {}, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = response.data;
      if (data.results) {
        setMinRecipes(data.results);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  const retrieveSavedRecipes = async () => {
    if (!userProfile) {
      console.log('UserProfile is null, skipping fetch.');
      return;
    }
    // Use axios to post data
    axios.post(`${url}/getRecipeUser`, { user_id: userProfile.user_id })
      .then(response => {
        const data = response.data;
        if (data.results && data.results.length > 0) {
          setPersonalRecipes(data.results);
        } else {
          console.log('No recipes found for this user.');
          setPersonalRecipes([]);
        }
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }

  const retrieveAvgIngred = async () => {
    if (!userProfile) {
      console.log('UserProfile is null, skipping fetch.');
      return;
    }
    // Use axios to post data
    axios.post(`${url}/avgIngredUser`, { user_id: userProfile.user_id })
      .then(response => {
        const data = response.data;
        if (data.averageIngredients) {
          const rounded = Math.round(data.averageIngredients);
          setAvgIngred(rounded);
        } else {

          setAvgIngred(0);
        }
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }






  return (

    <div className="container mt-3">
      {/* Personal and Recent Recipes Card */}
      <div className="row">
        <div className="col-12">
          <Card className="mb-3">
            <Card.Header>
              <i className="bi bi-person-circle me-2"></i> Personal and Recent Recipes
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-md-4 d-flex align-items-center justify-content-center">
                  <Link to="/recipes">
                    <Button variant="primary" >View All Recipes</Button>
                  </Link>
                </div>
                <div className="col-md-8">
                  <ListGroup>
                    {personalRecipes.slice(0, 5).map((recipe, index) => (
                      <ListGroup.Item key={recipe.recipe_id} className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img
                            src={recipe.image}
                            alt={recipe.recipe_name}
                            className="img-fluid rounded me-2"
                            style={{ width: '50px', height: '50px' }}
                          />
                          <div>{recipe.recipe_name}</div>
                        </div>

                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Card>
        <Card.Header><i class="bi bi-info-circle-fill"></i> Your Recipe Dashboard</Card.Header>
        <Card.Body>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="totalRecipes" title="Total Recipes">
              {sumRecipes > 0 ? (
                <div className='fun-fact'>
                  <h2 className='header'>Did you know?</h2>
                  <p className='paragraph'>
                    You added a total of <strong>{sumRecipes}</strong> recipes! Happy meal prepping!
                  </p>
                </div>) : (
                <div className='fun-fact'>
                  <h2 className='header'>Did you know?</h2>
                  <p className='paragraph'>
                    You only added a total of <strong>{sumRecipes}</strong> recipes! Keep meal prepping!
                  </p>
                </div>
              )}
            </Tab>
            <Tab eventKey="activityGraph" title="Activity Graph">
              {dailyRecipes &&
                <div style={{ width: '600px', height: '500px' }}>
                  <h3>Daily Recipes Chart</h3>
                  <Line data={chartData} options={chartOptions} />
                </div>
              }
            </Tab>
            <Tab eventKey="average" title="Average Ingredients">

              {avgIngred !== null ? (
                <div className='fun-fact'>
                  <h2 className='header'>Did you know?</h2>
                  <p className='paragraph'>
                    You use <strong>{avgIngred}</strong> ingredients on average in your recipes!
                  </p>
                </div>) : (
                <div className='fun-fact'>
                  <h2 className='header'>Did you know?</h2>
                  <p className='paragraph'>
                    You only use <strong>{avgIngred}</strong> ingredients on average in your recipes! Keep meal prepping!
                  </p>
                </div>
              )}
            </Tab>
            <Tab eventKey="mostPopular" title="Most Popular Recipe">
              <div className="row">
                <div className="col-md-4 d-flex align-items-center justify-content-center">
                  <Link to="/recipes">
                    <Button variant="primary" >View All Recipes</Button>
                  </Link>
                </div>
                <div className="col-md-8">
                  <ListGroup>
                    {maxRecipes && maxRecipes.map((recipe, index) => (
                      <ListGroup.Item key={recipe.recipe_id} className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img
                            src={recipe.image}
                            alt={recipe.recipe_name}
                            className="img-fluid rounded me-2"
                            style={{ width: '50px', height: '50px' }}
                          />
                          <div>{recipe.recipe_name}</div>
                        </div>

                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </Tab>
            <Tab eventKey="leastPopular" title="Least Popular Recipe">
              <div className="row">
                <div className="col-md-4 d-flex align-items-center justify-content-center">
                  <Link to="/recipes">
                    <Button variant="primary" >View All Recipes</Button>
                  </Link>
                </div>
                <div className="col-md-8">
                  <ListGroup>
                    {minRecipes && minRecipes.map((recipe, index) => (
                      <ListGroup.Item key={recipe.recipe_id} className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img
                            src={recipe.image}
                            alt={recipe.recipe_name}
                            className="img-fluid rounded me-2"
                            style={{ width: '50px', height: '50px' }}
                          />
                          <div>{recipe.recipe_name}</div>
                        </div>

                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </Tab>

          </Tabs>
        </Card.Body>
      </Card>
      {/* Groups Card */}
      <div className="row">
        <div className="col-12">
          <Card className="mb-3">
            <Card.Header>
              <i className="bi bi-people-fill me-2"></i> Groups
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-md-4 d-flex align-items-center justify-content-center">
                  <Link to="/groups">
                    <Button variant="primary" >View All Groups</Button>
                  </Link>
                </div>
                <div className="col-md-8">
                  <ListGroup>
                    {groups.slice(0, 5).map((group, index) => (
                      <ListGroup.Item key={group.group_id} className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <i className="bi bi-people me-2"></i> {/* Added margin to the right of the icon */}
                          <div>{group.group_name}</div>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>


  )
}

export default UserHomePage;
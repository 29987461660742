import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

function FacilityReportPage() {

    const calculatePercentages = (details, total) => {
        return details.map(detail => ({
            name: detail.name,
            percentage: total ? ((detail.counter / total) * 100).toFixed(2) : 0,
            counter: detail.counter // This will be either add_admin_counter or del_admin_counter
        }));
    };

    const [adminData, setAdminData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Percentage of Admins Added',
                data: [],
                backgroundColor: 'rgba(53, 162, 235, 0.5)', // Blue color
            },
        ],
    });
    const [totalCounter, setTotalCounter] = useState(0);

    const [delAdminData, setDelAdminData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Percentage of Admins Deleted',
                data: [],
                backgroundColor: 'rgba(255, 99, 132, 0.5)', // Red color
            },
        ],
    });
    const [totalDelCounter, setTotalDelCounter] = useState(0);

    const [adminEditData, setAdminEditData] = useState({
        labels: ['Admins Added', 'Admins Deleted'],
        datasets: [
            {
                label: 'Percentage of Admin Edit Actions',
                data: [],
                backgroundColor: ['rgba(100, 204, 120, 0.5)'], // Turquoise and Purple colors
            },
        ],
    });
    const [totalEditCounter, setTotalEditCounter] = useState(0);////--------------------
    const [searchUserData, setSearchUserData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Percentage of Search User Action',
                data: [],
                backgroundColor: 'rgba(255, 255, 100, 0.5)', // Yellow color
            },
        ],
    });
    const [totalSearchUserCounter, setTotalSearchUserCounter] = useState(0);

    const [searchManagerData, setSearchManagerData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Percentage of Search Manager Action',
                data: [],
                backgroundColor: 'rgba(255, 128, 0, 0.5)', // Light blue color
            },
        ],
    });
    const [totalSearchManagerCounter, setTotalSearchManagerCounter] = useState(0);

    const [searchRecipeData, setSearchRecipeData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Percentage of Search Recipe Action',
                data: [],
                backgroundColor: 'rgba(100, 159, 64, 0.5)', // Orange color
            },
        ],
    });
    const [totalSearchRecipeCounter, setTotalSearchRecipeCounter] = useState(0);

    const [searchGroupData, setSearchGroupData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Percentage of Search Group Action',
                data: [],
                backgroundColor: 'rgba(153, 102, 255, 0.5)', // Purple color
            },
        ],
    });
    const [totalSearchGroupCounter, setTotalSearchGroupCounter] = useState(0);

    const fetchData = async () => {
        try {
            // Fetch data for admin additions
            const addResponse = await axios.get('http://localhost:5000/api/admin/add');
            const addAdmins = addResponse.data.data;
            const addTotal = addResponse.data.totalAdds;
            setTotalCounter(addTotal);

            setAdminData({
                labels: addAdmins.map(admin => `${admin.name} | Num: ${admin.add_admin_counter}`),
                datasets: [{
                    label: 'Percentage of Admins Added',
                    data: addAdmins.map(admin => parseFloat(admin.percentage)),
                }],
            });

            // Fetch data for admin deletions
            const delResponse = await axios.get('http://localhost:5000/api/admin/del');
            const delAdmins = delResponse.data.data;
            const delTotal = delResponse.data.totalDels;
            setTotalDelCounter(delTotal);

            setDelAdminData({
                labels: delAdmins.map(admin => `${admin.name} | Num: ${admin.del_admin_counter}`),
                datasets: [{
                    label: 'Percentage of Admins Deleted',
                    data: delAdmins.map(admin => parseFloat(admin.percentage)),
                }],
            });

            // Fetch data for search user action
            const searchResponse = await axios.get('http://localhost:5000/api/search/user');
            const responseData = searchResponse.data;

            if (responseData && responseData.userData && Array.isArray(responseData.userData)) {
                const searchUserData = responseData.userData;
                const totals = responseData.totalSearchCounts;

                // Total counters
                setTotalSearchUserCounter(totals.total_search_users);
                setTotalSearchManagerCounter(totals.total_search_managers);
                setTotalSearchRecipeCounter(totals.total_search_recipes);
                setTotalSearchGroupCounter(totals.total_search_groups);

                // Individual details - this assumes `search_user`, `search_manager`, `search_recipe`, `search_group` are available
                setSearchUserData({
                    labels: searchUserData.map(user => `${user.user_name} | Num: ${user.search_user}`),
                    datasets: [{
                      label: 'Percentage of Search User Action',
                      data: searchUserData.map(user => ((user.search_user / totals.total_search_users) * 100).toFixed(2)),
                    }],
                  });

                setSearchManagerData({
                    labels: searchUserData.map(user => `${user.user_name} | Num: ${user.search_manager}`),
                    datasets: [{
                        label: 'Percentage of Search Manager Action',
                        data: searchUserData.map(user => ((user.search_manager / totals.total_search_managers) * 100).toFixed(2)),
                    }],
                });

                setSearchRecipeData({
                    labels: searchUserData.map(user => `${user.user_name} | Num: ${user.search_recipe}`),
                    datasets: [{
                        label: 'Percentage of Search Recipe Action',
                        data: searchUserData.map(user => ((user.search_recipe / totals.total_search_recipes) * 100).toFixed(2)),
                    }],
                });

                setSearchGroupData({
                    labels: searchUserData.map(user => `${user.user_name} | Num: ${user.search_group}`),
                    datasets: [{
                        label: 'Percentage of Search Group Action',
                        data: searchUserData.map(user => ((user.search_group / totals.total_search_groups) * 100).toFixed(2)),
                    }],
                });

            } else {
                throw new Error('Invalid response structure');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Call the fetchData function inside useEffect
    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        const fetchEditData = async () => {
            try {
                // Assuming you have the totalCounter and totalDelCounter from your previous data fetches
                let totalEdits = parseInt(totalCounter) + parseInt(totalDelCounter);
                setTotalEditCounter(totalEdits);

                const percentageAdded = (totalCounter / totalEdits) * 100;
                const percentageDeleted = (totalDelCounter / totalEdits) * 100;

                setAdminEditData(prevData => ({
                    ...prevData,
                    datasets: [{
                        ...prevData.datasets[0],
                        data: [percentageAdded, percentageDeleted]
                    }]
                }));
            } catch (error) {
                console.error('Error fetching edit data:', error);
            }
        };

        fetchEditData();
    }, [totalCounter, totalDelCounter]);
    
    
    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => {
                    // Value is the percentage
                    // Label will be the admin's name and counter
                    const counter = context.chart.data.datasets[context.datasetIndex].data[context.dataIndex];
                    const total = context.chart.data.datasets[context.datasetIndex].totalCounter;
                    return `${value}% (${counter}/${total})`;
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Percentage (%)',
                },
                ticks: {
                    callback: function (value) {
                        return value + "%";
                    }
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Admin Name / User Name',
                },
            },
        },
    };
    
    const editOptions = {
        responsive: true,
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value) => `${value.toFixed(2)}%`
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Percentage (%)',
                },
                ticks: {
                    callback: function (value) {
                        return value + "%";
                    }
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Action Type',
                },
            },
        },
    };
    
    
    return (
        <>
            <div className="container mt-4">
                <h2>Admin Addition Report</h2>
                <div>Total Number of Added_admin Action: {totalCounter}</div>
                <Bar data={adminData} options={options} />
            </div>

            <div className="container mt-4">
                <h2>Admin Deletion Report</h2>
                <div>Total Number of Deleted_admin Action: {totalDelCounter}</div>
                <Bar data={delAdminData} options={options} />
            </div>

            <div className="container mt-4">
                <h2>Total Admin Edit Actions Report</h2>
                <div>Total Number of Edit Actions: {totalEditCounter}</div>
                <Bar data={adminEditData} options={editOptions} />
            </div>

            <div className="container mt-4">
                <h2>Search User Report</h2>
                <div>Total Number of Search User Action: {totalSearchUserCounter}</div>
                <Bar data={searchUserData} options={options} />
            </div>

            <div className="container mt-4">
                <h2>Search Manager Report</h2>
                <div>Total Number of Search Manager Action: {totalSearchManagerCounter}</div>
                <Bar data={searchManagerData} options={options} />
            </div>

            <div className="container mt-4">
                <h2>Search Recipe Report</h2>
                <div>Total Number of Search Recipe Action: {totalSearchRecipeCounter}</div>
                <Bar data={searchRecipeData} options={options} />
            </div>

            <div className="container mt-4">
                <h2>Search Group Report</h2>
                <div>Total Number of Search Group Action: {totalSearchGroupCounter}</div>
                <Bar data={searchGroupData} options={options} />
            </div>
        </>
    );
}

export default FacilityReportPage;

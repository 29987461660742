import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useAuth } from "../context/authContext";
import axios from "axios";

const adminUrl = process.env.REACT_APP_HOST_API + "/api/admin";

function SettingPage() {
  const { token } = useAuth();
  const [credentials, setCredentials] = useState({
    currentPassword: "",
    newPassword: "",
    reenterPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrorMessage("");
  };

  const handleCloseModal = () => {
    setCredentials({
      currentPassword: "",
      newPassword: "",
      reenterPassword: "",
    });
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(adminUrl + "/changePassword", credentials, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = response.data;
      console.log(response)
      if (data.success) {
        handleCloseModal();
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "An error occurred.");
    }
  };

  return (
    <div className="container mt-4">
      <h2>Setting</h2>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Change Password
      </Button>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Current Password"
                value={credentials.currentPassword}
                onChange={handleChange}
                name="currentPassword"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter New Password"
                value={credentials.newPassword}
                onChange={handleChange}
                name="newPassword"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Reenter New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Reenter New Password"
                value={credentials.reenterPassword}
                onChange={handleChange}
                name="reenterPassword"
              />
            </Form.Group>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default SettingPage;

import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../context/authContext";

export const PrivateRouteAdmin = ({ children }) => {
	const { userProfile } = useAuth();
	return userProfile && userProfile.role === "admin" ? (
		children
	) : (
		<Navigate to="/" replace />
	);
};

export const PrivateLayout = () => {
	const { userProfile } = useAuth();
	return userProfile ? <Outlet /> : <Navigate to="/" replace />;
};

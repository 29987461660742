import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { useAuth } from "../context/authContext";
import Login from "./Login";
import SignUp from "./Signup";
import ChangePassword from "./ChangePassword";
import logo from '../assets/quickcook-logo.png';

function NavigationBar() {
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [showSignupModal, setShowSignupModal] = useState(false);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const { token, setToken, userProfile, setUserProfile } = useAuth();
	const navigate = useNavigate();

	const handleLogout = () => {
		localStorage.removeItem("token");
		setToken(null);
		setUserProfile(null);
		setShowLoginModal(false);
		navigate("/home");
	};

	return (
		<>
			<Navbar expand="md" className="bg-body-tertiary mb-3">
				<Container>
					<Navbar.Brand as={Link} to={userProfile ? "/userhome" : "/home"} style={{ display: 'flex', alignItems: 'center' }}>
						<img
							src={logo}
							alt="QuickCook Logo"
							style={{ width: "100px", height: "auto", marginRight: '-25px' }}
						/>
						<span>QuickCook</span>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							{token !== null ? (
								<>
									<Nav.Link as={Link} to="/groups">
										Groups <i className="bi bi-people-fill"></i>
									</Nav.Link>
									{userProfile && userProfile.role === "admin" && (
										<Nav.Link as={Link} to="/admin">
											Admin <i className="bi bi-key"></i>
										</Nav.Link>
									)}
									{userProfile && userProfile.role === "admin" && (
										<Nav.Link as={Link} to="/report">
											Report <i className="bi bi-bar-chart-line"></i>
										</Nav.Link>
									)}
								</>
							) : (
								""
							)}
							{userProfile && (
								<Nav.Link as={Link} to="/recipes">
									Recipes <i className="bi bi-egg-fried"></i>
								</Nav.Link>
							)}
						</Nav>
						{token !== null ? (
							<Nav>
								<NavDropdown
									id="nav-dropdown"
									title="Setting"
									menuVariant="dark"
								>
									<NavDropdown.Item onClick={() => setShowChangePasswordModal(true)}>
										Change Password
									</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item onClick={() => handleLogout()}>
										Log out
									</NavDropdown.Item>
								</NavDropdown>
							</Nav>
						) : (
							<Nav>
								<Button variant="dark" className="me-2" onClick={() => setShowLoginModal(true)}>
									Login
								</Button>
								<Button variant="dark" onClick={() => setShowSignupModal(true)}>
									Sign up
								</Button>
							</Nav>
						)}
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Login
				show={showLoginModal}
				handleClose={() => setShowLoginModal(false)}
			/>
			<SignUp
				show={showSignupModal}
				handleClose={() => setShowSignupModal(false)}
			/>
			<ChangePassword
				show={showChangePasswordModal}
				handleClose={() => setShowChangePasswordModal(false)}
			/>
		</>
	);
}

export default NavigationBar;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Button, Modal, Container, Row, Col, ListGroup, Form } from 'react-bootstrap';
import { jsPDF } from "jspdf";
import axios from 'axios';
import './IndivGroupPage.css';
import { useAuth } from '../context/authContext';

const url = process.env.REACT_APP_HOST_API;

function IndivGroupPage() {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [group, setGroup] = useState({});
  const [recipes, setRecipes] = useState([]);
  const [savedRecipes, setSavedRecipes] = useState([]);
  const [isManager, setIsManager] = useState(false);
  const [showRecipeModal, setShowRecipeModal] = useState(false);
  const [showGroceryModal, setShowGroceryModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const [ingred, setIngred] = useState([]);
  const [groceryList, setGroceryList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userId, setUserId] = useState('');
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 19).replace('T', ' ');
  const { userProfile, setUserProfile } = useAuth();
  const [groupStats, setGroupStats] = useState({
    mostRequestedIngredient: '',
    recipesAdded: 0,
    avgRecipesPerUser: 0,
    totalUsers: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [recipesPerPage] = useState(4);
  const indexOfLastRecipe = currentPage * recipesPerPage;
  const indexOfFirstRecipe = indexOfLastRecipe - recipesPerPage;
  const nextPage = () => setCurrentPage(prevPage => prevPage + 1);
  const prevPage = () => setCurrentPage(prevPage => prevPage - 1);
  const currentRecipes = recipes.slice(indexOfFirstRecipe, indexOfLastRecipe);
  useEffect(() => {
    fetchAllRecipes();
    fetchGroupDetails();
    fetchGroupManager();
    fetchGroupRecipes();
    fetchGroupStats();

  }, []);
  const fetchAllRecipes = async () => {
    try {
      const response = await axios.post(`${url}/getAllRecipes`, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      setRecipes(response.data.results);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchGroupDetails = async() =>{
    try {
      const response =await axios.get(`${url}/group/${groupId}`, {
        headers: {
          "Content-Type": "application/json",
        }
      });
      
      setGroup(response.data.group);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
    
}
const fetchGroupManager = async() =>{
  try {
    const response = await axios.get(`${url}/isGroupManager/${groupId}?user_id=${userProfile.user_id}`, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    setIsManager(response.data.isManager);
  } catch (error) {
    console.error('Error fetching data: ', error);
  }
  
}
const fetchGroupRecipes = async() =>{
  try {
    const response = await axios.get(`${url}/groupRecipes/${groupId}`, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    if (response.data.results.length > 0)
    {
      setSavedRecipes(response.data.results);
    }
    
    console.log(savedRecipes)
    console.log(response.data.results);
  } catch (error) {
    console.error('Error fetching data: ', error);
  }
  
}

const fetchGroupStats = async () => {
  try {
    const response = await axios.get(`${url}/group/${groupId}/stats`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        user_id: userProfile.user_id,
      },
    });
    
    if (response.status === 200) {
      console.log("Group stats response:", response.data);

      setGroupStats(response.data);
      setIsManager(true);
    } else {
      setIsManager(false);
    }
  
  } catch (error) {
    console.error('Error fetching group stats: ', error);
  }
};


  const handleAddRecipe = async (recipe) => {
    try {
      const response = await axios.post(`${url}/group/${groupId}/add-recipe`, { recipe_id: recipe.recipe_id, user_id: userProfile.user_id });
      if (response.data.success) {
        setSavedRecipes(prev => [...prev, recipe]);
      } else {
        alert('Failed to add recipe to group.');
      }
    } catch (error) {
      console.error("Failed to add recipe to group:", error);
    }
  };

  const handleRemoveRecipe = async (recipe) => {
    const isConfirmed = window.confirm("Are you sure you want to delete?");
    if (isConfirmed) {
      try {
        const response = await axios.post(`${url}/group/${groupId}/remove-recipe`, { recipe_id: recipe.recipe_id });
        if (response.data.success) {
          setSavedRecipes(prev => prev.filter(r => r.recipe_id !== recipe.recipe_id));
        } else {
          alert('Failed to remove recipe from group.');
        }
      } catch (error) {
        console.error("Failed to remove recipe from group:", error);
      }
    }
  };

  const handleShowRecipeModal = async (recipe) => {
    setSelectedRecipe(recipe);
    try {
      const response = await axios.post(`${url}/findIngredient`, { recipe_id: recipe.recipe_id });
      setIngred(response.data.results);
      setShowRecipeModal(true);
    } catch (error) {
      console.error('Error fetching ingredients:', error);
    }
  };

  const getIngredientList = async () => {
    const fetchPromises = savedRecipes.map(recipe =>
      axios.post(`${url}/findIngredient`, { recipe_id: recipe.recipe_id })
        .then(response => response.data.results.map(ingredient => ({
          food_name: ingredient.food_name,
          quantity: ingredient.quantity,
          from_recipe: recipe.recipe_name
        })))
        .catch(error => {
          console.error('Error fetching ingredients:', error);
          return [];
        })
    );

    const allResults = await Promise.all(fetchPromises);
    const allIngredients = [];
    allResults.forEach(ingredients => {
      ingredients.forEach(ingredient => {
        const existingIngredient = allIngredients.find(
          ing => ing.food_name.toLowerCase() === ingredient.food_name.toLowerCase()
        );
        if (existingIngredient) {
          existingIngredient.quantity += ingredient.quantity;
          existingIngredient.from_recipe += `, ${ingredient.from_recipe}`;
        } else {
          allIngredients.push(ingredient);
        }
      });
    });

    return allIngredients;
  };

  const handleGroceryList = async () => {
    const ingredients = await getIngredientList();
    if (ingredients.length > 0) {
      setGroceryList(ingredients);
      setShowGroceryModal(true);
    }
  };

  const exportToPDF = () => {
    const doc = new jsPDF();

    const leftColumnX = 10;
    const rightColumnX = 105;
    let y = 20;
    const lineHeight = 10;
    const pageHeight = doc.internal.pageSize.height;
    let currentColumn = 'left';

    doc.setFontSize(12);
    doc.text('Grocery List', 10, 15);

    groceryList.forEach((item) => {
      if (y > pageHeight - 20) {
        doc.addPage();
        y = 20;
        currentColumn = 'left';
      }

      const x = currentColumn === 'left' ? leftColumnX : rightColumnX;

      doc.text(`- ${item.food_name} (${item.quantity}) - From: ${item.from_recipe}`, x, y);

      if (currentColumn === 'left') {
        currentColumn = 'right';
      } else {
        y += lineHeight;
        currentColumn = 'left';
      }
    });

    doc.save('grocery-list.pdf');
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${url}/search`, { searchQuery, selectedFilter: 'Recipes' });
      setRecipes(response.data.results);
    } catch (error) {
      console.error('Error searching recipes:', error);
    }
  };

  const handleAddUser = async () => {
    try {
      const response = await axios.post(`${url}/group/${groupId}/add-user`, { user_id: userId });
      if (response.data.success) {
        alert('User added to the group successfully.');
        setUserId('');
        setShowAddUserModal(false);
      } else {
        alert('Failed to add user to the group.');
      }
    } catch (error) {
      console.error('Error adding user to the group:', error);
    }
  };

  return (
    <div className="indiv-group-page">
      <div className="header">
        <Button variant="outline-secondary" className="back-button" onClick={() => navigate('/groups')}>Back to Groups</Button>
        <h1>{group.group_name}</h1>
        <div className="role-info">
          <span>Role: {isManager ? 'Manager' : 'User'}</span>
          {isManager && (
            <Button variant="outline-primary" className="add-user-button" onClick={() => setShowAddUserModal(true)}>Add User</Button>
          )}
        </div>
      </div>
  
      <Container>
        <Card className="group-card">
          <Card.Header as="h3" className="group-title">
            Group Recipes
          </Card.Header>
          <Card.Body className="group-recipes">
            <Row>
              <Col>
                <ListGroup>
                  {!!savedRecipes && savedRecipes.map((recipe) => (
                    <ListGroup.Item key={recipe.recipe_id} className="recipe-item">
                      <div className="recipe-info">
                        <img
                          src={recipe.image}
                          alt={recipe.recipe_name}
                          className="recipe-image"
                        />
                        <div className="recipe-name">{recipe.recipe_name}</div>
                      </div>
                      <div className="recipe-actions">
                        <Button
                          variant="outline-info"
                          size="sm"
                          onClick={() => handleShowRecipeModal(recipe)}
                          className="view-details-btn"
                        >
                          View Details
                        </Button>
                        {isManager && (
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleRemoveRecipe(recipe)}
                            className="remove-btn"
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <div className="grocery-list-btn">
                  <Button
                    variant="outline-primary"
                    size="lg"
                    disabled={savedRecipes.length === 0}
                    onClick={handleGroceryList}
                  >
                    Build Grocery List
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
  
      {isManager && groupStats && Object.keys(groupStats).length > 0 && (
  <Container className="group-stats-container" style={{ marginTop: '20px', marginBottom: '20px' }}>
    <Card className="group-stats-card">
      <Card.Header as="h3" className="group-stats-title">
        Group Stats
      </Card.Header>
      <Card.Body>
        <ListGroup>
          {/* Display the most requested ingredient */}
          <ListGroup.Item>
            <strong>Most Requested Ingredient:</strong> {groupStats.mostRequestedIngredient}
          </ListGroup.Item>

          {/* Display the total number of recipes added */}
          <ListGroup.Item>
            <strong>Recipes Added:</strong> {groupStats.recipesAdded}
          </ListGroup.Item>

          {/* Display the average number of recipes per user */}
          <ListGroup.Item>
            <strong>Average Recipes per User:</strong> {groupStats.avgRecipesPerUser}
          </ListGroup.Item>

          {/* Display the total number of users */}
          <ListGroup.Item>
            <strong>Total Users:</strong> {groupStats.totalUsers}
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  </Container>
)}

  
      <Container className="search-container" style={{ marginTop: '20px' }}>
        <Form onSubmit={handleSearch} className="search-form">
          <Row>
            <Col>
              <Form.Control
                type="text"
                placeholder="Search recipes..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
                style={{ marginBottom: '10px' }}
              />
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit" className="search-button">Search</Button>
            </Col>
          </Row>
        </Form>
      </Container>
  
      <div className="d-flex justify-content-end mb-2">
        <Button variant="secondary" onClick={prevPage} disabled={currentPage === 1} className="rounded-circle me-2" style={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <i className="bi bi-arrow-left"></i>
        </Button>
        <Button variant="secondary" onClick={nextPage} disabled={currentPage === Math.ceil(recipes.length / recipesPerPage)} className="rounded-circle" style={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <i className="bi bi-arrow-right"></i>
        </Button>
      </div>
      <Row>
        {currentRecipes.map(recipe => (
          <Col key={recipe.recipe_id} md={3} className="mb-3" onClick={() => handleShowRecipeModal(recipe)}>
            <Card style={{ height: '250px', position: 'relative' }}>
              <Card.Img variant="top" src={recipe.image} alt={recipe.recipe_name} style={{ maxHeight: '200px', objectFit: 'cover' }} />
              <Card.Body>
  <Card.Title className="text-sm">{recipe.recipe_name}</Card.Title>
  <Button
    variant="primary"
    disabled={savedRecipes.some(savedRecipe => savedRecipe.recipe_id === recipe.recipe_id)}
    style={{ position: 'absolute', top: '0px', right: '0px' }}
    onClick={(e) => {
      e.stopPropagation();
      handleAddRecipe(recipe);
    }}
  >
    +
  </Button>
</Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
  
      {selectedRecipe && showRecipeModal && (
        <Modal show={showRecipeModal} onHide={() => setShowRecipeModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedRecipe.recipe_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Ingredients:</div>
            <ul>
              {ingred.map((ingredient, index) => (
                <li key={index}>{ingredient.quantity} of {ingredient.food_name}</li>
              ))}
            </ul>
            <div>Instructions:</div>
            <ol>
              {selectedRecipe.instruction.split('.').map((step, index) =>
                step.trim() ? <li key={index}>{step.trim()}</li> : null
              )}
            </ol>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowRecipeModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
  
      {showGroceryModal && (
        <Modal show={showGroceryModal} onHide={() => setShowGroceryModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Grocery List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {groceryList.map((item, index) => (
                <li key={index}>{item.food_name} ({item.quantity}) - From: {item.from_recipe}</li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={exportToPDF}>
              Export to PDF
            </Button>
            <Button variant="secondary" onClick={() => setShowGroceryModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
  
      {isManager && (
        <Modal show={showAddUserModal} onHide={() => setShowAddUserModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add User to Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>User ID:</Form.Label>
                <Form.Control
                  type="text"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddUserModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddUser}>
              Add User
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
  
}

export default IndivGroupPage;
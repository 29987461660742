import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";

const signupUrl = process.env.REACT_APP_HOST_API + "/api/admin/signup";

function Signup({ show, handleClose }) {
	const [credentials, setCredentials] = useState({
		username: "",
		password: "",
		reenterPassword: "",
	});
	const [errorMessage, setErrorMessage] = useState("");

	const handleChange = (e) => {
		const { name, value } = e.target;
		setCredentials((prevState) => ({
			...prevState,
			[name]: value,
		}));
		setErrorMessage("");
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post(signupUrl, credentials);
			if (response.data.success) {
				handleClose();
			} else {
				setErrorMessage(response.data.message || "Incorrect credentials.");
			}
			setCredentials({ username: "", password: "", reenterPassword: ""});
		} catch (error) {
			setErrorMessage(error.response?.data?.message || "An error occurred.");
		}
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Sign up</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="username">
						<Form.Label>Username</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter Username"
							value={credentials.username}
							onChange={handleChange}
							name="username"
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="password">
						<Form.Label>Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Enter Password"
							value={credentials.password}
							onChange={handleChange}
							name="password"
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="reenterPassword">
						<Form.Label>Reenter Password</Form.Label>
						<Form.Control
							type="password"
							placeholder="Reenter Password"
							value={credentials.reenterPassword}
							onChange={handleChange}
							name="reenterPassword"
						/>
					</Form.Group>
					{errorMessage && (
						<div className="alert alert-danger" role="alert">
							{errorMessage}
						</div>
					)}
					<Button variant="primary" type="submit">
						Submit
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
export default Signup;

// HomePage.js
import { Card, Button, Container, ListGroup, Row, Col, CardTitle, Form, Tab, Tabs } from 'react-bootstrap';
import { Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/authContext";
import './HomePage.css';
import SignUp from "../components/Signup";
import { ConeStriped } from 'react-bootstrap-icons';



const url = process.env.REACT_APP_HOST_API;

function HomePage() {
  const { userProfile, setUserProfile } = useAuth();
  const [showSignupModal, setShowSignupModal] = useState(false);
  // const handleStartedClick = () => {
  //   console.log("button clicked")

  //   return (
  //     <SignUp
  // 			show={true}
  // 			handleClose={() => setShowSignupModal(false)}
  // 		/>
  //   )
  // };
  if (userProfile) {
    return <Navigate to="/userhome" replace={true} />;
  }
  // const [userId, setUserId] = useState("");
  return (
    // // If the user is not logged in, show the login/signup UI
    // <div className="container mt-3">
    //   <div className="d-flex flex-wrap">
    //     <div className="col-md-4 mb-2">
    //       <Form className="d-flex">
    //         <Form.Control type="search" placeholder="Search" className="me-2" aria-label="Search" />
    //         <Button variant="outline-success">Search</Button>
    //       </Form>
    //     </div>
    //     {/* Add more content or forms for login/signup here */}

    //   </div>
    // </div>
    <div className="homepage" >
      <div className="motto-text">
        <h1 className="motto-text">Let's Get Cooking</h1>
        <h1 className="motto-text">&</h1>
        <h1 className="motto-text">Easier Meal Prepping!</h1>
        <h1 className="motto"></h1>
        <h1 className="motto"></h1>
        <button className="get-started" onClick={() => setShowSignupModal(true)}>
          Get Started
        </button>
        {showSignupModal && (
          <SignUp
            show={showSignupModal}
            handleClose={() => setShowSignupModal(false)}
          />
        )}

      </div>
    </div>
  );



}

export default HomePage;

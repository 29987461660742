import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';

const AddRecord = ({ show, onClose, onSubmit, selectedTable }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData({});
  };

  const renderFormFields = () => {
    switch (selectedTable) {
      case 'Users':
        return (
          <>
            <Form.Group controlId="user_name">
              <Form.Label>User Name</Form.Label>
              <Form.Control type="text" name="user_name" onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="user_pass">
              <Form.Label>User Password</Form.Label>
              <Form.Control type="password" name="user_pass" onChange={handleChange} required />
            </Form.Group>
          </>
        );
      case 'Recipes':
        return (
          <>
            <Form.Group controlId="recipe_name">
              <Form.Label>Recipe Name</Form.Label>
              <Form.Control type="text" name="recipe_name" onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId=""> 
              <Form.Label>Instructions</Form.Label>
              <Form.Control as="textarea" name="instruction" onChange={handleChange} required />
            </Form.Group>
          </>
        );
      case 'Groups':
        return (
          <>
            <Form.Group controlId="group_name">
              <Form.Label>Group Name</Form.Label>
              <Form.Control type="text" name="group_name" onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="group_pass">
              <Form.Label>Group Password</Form.Label>
              <Form.Control type="password" name="group_pass" onChange={handleChange} required />
            </Form.Group>
          </>
        );
      case 'Managers':
        return (
          <> 
            <Form.Group controlId="manager_name">
              <Form.Label>Manager Name</Form.Label>
              <Form.Control type="text" name="manager_name" onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="user_id">
              <Form.Label>User ID</Form.Label>
              <Form.Control type="number" name="user_id" onChange={handleChange} required />
            </Form.Group>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add {selectedTable.slice(0, -1)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {renderFormFields()}
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddRecord;
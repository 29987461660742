// AdminPage.js
import AddRecord from "../components/AddRecord";
import { useAuth } from "../context/authContext";
import React, { useState, useEffect } from "react";
import {
	Form,
	Button,
	Dropdown,
	Table,
	Modal,
	ListGroup,
	Row, Col, Card, Switch
} from "react-bootstrap";
import { useGroups } from "../context/groupContext";
const url = process.env.REACT_APP_HOST_API + "/api/admin";

function AdminPage() {
	const { token } = useAuth();
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedFilter, setSelectedFilter] = useState("Users");
	const [results, setResults] = useState([]);
	const [updateItem, setUpdateItem] = useState(null);
	const [updateItemId, setUpdateItemId] = useState(null);
	const [showupdateForm, setShowUpdateForm] = useState(false);
	const { groups } = useGroups();
	const [selectedGroupId, setSelectedGroupId] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [selectedRecipeId, setSelectedRecipeId] = useState(null);
	const [showAddRecord, setShowAddRecord] = useState(false);
	const [detailModalShow, setDetailModalShow] = useState(false);
	const [detailContent, setDetailContent] = useState('');
	const [detailTitle, setDetailTitle] = useState('');

	const handleDetailModal = (title, content) => {
		setDetailTitle(title);
		setDetailContent(content);
		setDetailModalShow(true);
	};

	const handleSearch = async (tracking) => {
		try {
			const response = await fetch(url + "/search", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`
				},
				body: JSON.stringify({ searchQuery, selectedFilter, tracking }),
			});

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			setResults(data.results);
		} catch (error) {
			console.error(
				"There has been a problem with your fetch operation:",
				error
			);
		}
	};
	const handleDelete = async (deleteItem) => {
		const isConfirmed = window.confirm("Are you sure you want to delete?");
		if (isConfirmed) {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_HOST_API}/delete`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ selectedFilter, deleteItem }),
					}
				);

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				handleSearch();
			} catch (error) {
				console.error(
					"There has been a problem with your fetch operation:",
					error
				);
			}
		}
	};
	const handleEdit = (item, itemId) => {
		const defaultItem = {
			user_name: '',
			user_pass: '',
			recipe_name: '',
			instruction: '',
			group_name: '',
			group_pass: '',
			manager_name: '',
			// Add other fields as needed
		};

		setUpdateItem({ ...defaultItem, ...item });
		setUpdateItemId(itemId);
		setShowUpdateForm(true);
	};

	const handleUpdateFormChange = (event) => {
		const fieldName = event.target.name; // The 'name' attribute of the input field
		const fieldValue = event.target.value; // The current value of the input field

		// Create a new editingItem object with the updated field value
		const newUpdateItem = { ...updateItem, [fieldName]: fieldValue };

		// Update the editingItem state with the new object
		setUpdateItem(newUpdateItem);
	};
	const renderUpdate = (item) => {
		switch (selectedFilter) {
			case "Users":
				return (
					<>
						<td>
							<input
								type="text"
								name="user_name"
								value={updateItem.user_name || ''}
								onChange={handleUpdateFormChange}
							/>
						</td>
						<td>
							<input
								type="text"
								name="user_pass"
								value={updateItem.user_pass}
								onChange={handleUpdateFormChange}
							/>
						</td>
					</>
				);
			case "Recipes":
				return (
					<>
						<td>
							<input
								type="text"
								name="recipe_name"
								value={updateItem.recipe_name}
								onChange={handleUpdateFormChange}
							/>
						</td>
						<td></td>
						<td></td>
						<td>
							<input
								type="text"
								name="instruction"
								value={updateItem.instruction}
								onChange={handleUpdateFormChange}
							/>
						</td>
						<td></td>
					</>
				);
			case "Groups":
				return (
					<>
						{results.map((group) => (
							<tr key={group.group_id}>
								<td>{group.group_id}</td>
								{showupdateForm && group.group_id === updateItemId ? (
									<>
										{renderUpdate(group)}
										<td>
											<button onClick={handleSaveClick}>Save</button>
										</td>
									</>
								) : (
									<>
										<td>{group.group_name}</td>
										<td>
											<input
												type="password"
												value={group.group_pass}
												readOnly
												style={{
													border: "none",
													background: "transparent",
													width: "100%",
													padding: 0,
												}}
											/>
										</td>
										<td>
											<i
												className="bi bi-pencil-square text-primary"
												style={{ cursor: "pointer", marginRight: "10px" }}
												onClick={() => handleEdit(group, group.group_id)}
											></i>{" "}
											{/* Edit icon */}
											<i
												className="bi bi-trash-fill text-danger"
												style={{ cursor: "pointer" }}
												onClick={() => handleDelete(group.group_id)}
											></i>{" "}
											{/* Delete icon */}
										</td>
									</>
								)}
							</tr>
						))}
						<td>
							<input
								type="text"
								name="group_name"
								value={updateItem.group_name}
								onChange={handleUpdateFormChange}
							/>
						</td>
						<td>
							<input
								type="text"
								name="group_pass"
								value={updateItem.group_pass}
								onChange={handleUpdateFormChange}
							/>
						</td>
					</>
				);
			case "Managers":
				return (
					<>
						<td>
							<input
								type="text"
								name="manager_name"
								value={updateItem.manager_name}
								onChange={handleUpdateFormChange}
							/>
						</td>
						<td></td>
					</>
				);
		}
	};
	const handleSaveClick = async () => {
		console.log(updateItem);
		console.log(updateItemId);
		try {
			const response = await fetch(
				`${process.env.REACT_APP_HOST_API}/update/${selectedFilter}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ updateItem }),
				}
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			handleSearch();
		} catch (error) {
			console.error(
				"There has been a problem with your fetch operation:",
				error
			);
		}
		setShowUpdateForm(false);
		setUpdateItem(null);
		setUpdateItemId(null);
	};

	const handleAddRecipeToGroup = async (recipeId) => {
		if (selectedGroupId) {
			try {
				const checkResponse = await fetch(
					`${process.env.REACT_APP_HOST_API}/checkRecipeInGroup?recipeId=${recipeId}&groupId=${selectedGroupId}`
				);
				const { exists } = await checkResponse.json();

				if (exists) {
					console.log("Recipe already exists in the group");
					return;
				}

				const response = await fetch(
					`${process.env.REACT_APP_HOST_API}/addRecipeToGroup`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ recipeId, groupId: selectedGroupId }),
					}
				);

				if (response.ok) {
					console.log("Recipe added to group successfully");
				} else {
					console.error("Failed to add recipe to group");
				}
			} catch (error) {
				console.error("Error adding recipe to group:", error);
			}
		}
	};

	const handleAdminToggle = async (userId, currentIsAdmin) => {
		const newIsAdmin = !currentIsAdmin; // Toggle the current state to the new state

		try {
			const response = await fetch(`${url}/updateUserRole`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}` // Ensure the authorization token is included in the header
				},
				body: JSON.stringify({ userId, newRole: newIsAdmin ? 'admin' : 'user' }),
			});

			if (!response.ok) {
				const errorData = await response.json();
				console.error("Failed to update user role with response: ", errorData);
				throw new Error("Failed to update user role");
			}

			const data = await response.json();
			if (data.success) {
				// Update the user list state to reflect the role change only after the response is successful
				setResults(results.map(user => {
					if (user.user_id === userId) {
						return { ...user, isAdmin: newIsAdmin }; // Update the isAdmin property for the toggled user
					}
					return user; // Return other users as is
				}));
				handleSearch()
			}

		} catch (error) {
			console.error("Error updating user role:", error);
		}
	};


	const renderResultsTable = () => {
		switch (selectedFilter) {
			case "Users":
				return (
					<Row xs={1} md={3} className="g-4">
						{results.map((user) => (
							<Col key={user.user_id}>
								<Card>
									<Card.Body>
										{showupdateForm && user.user_id === updateItemId ? (
											<>
												<Card.Title>
													<input
														type="text"
														name="user_name"
														value={updateItem ? updateItem.user_name : ''}
														onChange={handleUpdateFormChange}
													/>
												</Card.Title>
												<Card.Subtitle className="mb-2 text-muted">User ID: {user.user_id}</Card.Subtitle> 
												<Button onClick={handleSaveClick}>Save</Button>
											</>
										) : (
											<>
												<Card.Title>{user.user_name}</Card.Title>
												<Card.Subtitle className="mb-2 text-muted">User ID: {user.user_id}</Card.Subtitle>
												<div className="d-flex align-items-center justify-content-between">
													<div>
														<Button variant="primary" onClick={() => handleEdit(user, user.user_id)} style={{ marginRight: '8px' }}>Edit</Button>
														<Button variant="danger" onClick={() => handleDelete(user.user_id)}>Delete</Button>
													</div>
													<Form.Switch
														id={`custom-switch-${user.user_id}`}
														label="Admin"
														checked={user.role === "admin"}
														onChange={() => handleAdminToggle(user.user_id, user.role === 'admin')} // Ensure it toggles the isAdmin state
														className="mt-1"
													/>

												</div>
											</>
										)}
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
				);

			case "Recipes":
				return (
					<Row xs={1} md={3} className="g-4">
						{results.map((recipe) => (
							<Col key={recipe.recipe_id}>
								<Card>
									<Card.Img variant="top" src={recipe.image} alt={recipe.recipe_name} style={{ maxHeight: '180px', objectFit: 'cover' }} />
									<Card.Body>
										{showupdateForm && recipe.recipe_id === updateItemId ? (
											<>
												<input
													type="text"
													name="recipe_name"
													className="form-control mb-2"
													value={updateItem ? updateItem.recipe_name : ''}
													onChange={handleUpdateFormChange}
												/>
												<textarea
													name="description"
													className="form-control mb-2"
													value={updateItem ? updateItem.description : ''}
													onChange={handleUpdateFormChange}
												/>
												<textarea
													name="ingredients"
													className="form-control mb-2"
													value={updateItem ? updateItem.ingredients : ''}
													onChange={handleUpdateFormChange}
												/>
												<textarea
													name="instructions"
													className="form-control mb-2"
													value={updateItem ? updateItem.instructions : ''}
													onChange={handleUpdateFormChange}
												/>
												<Button variant="primary" onClick={handleSaveClick}>Save</Button>
											</>
										) : (
											<>
												<Card.Title>{recipe.recipe_name}</Card.Title>
												<Card.Text>{recipe.description}</Card.Text>
												<Card.Text>
													<strong>Ingredients:</strong> {recipe.ingredient_num || 'N/A'}
												</Card.Text>
												<Card.Text>
													<strong>Instructions:</strong> <Button variant="link" onClick={() => handleDetailModal("Instructions", recipe.instruction)}>Read Details</Button>
												</Card.Text>
												<Button variant="primary" onClick={() => handleEdit(recipe, recipe.recipe_id)} className="me-2">Edit</Button>
												<Button variant="danger" onClick={() => handleDelete(recipe.recipe_id)}>Delete</Button>
											</>
										)}
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
				);
			case "Groups":
				return (
					<Row xs={1} md={3} className="g-4">
						{results.map((group) => (
							<Col key={group.group_id}>
								<Card>
									<Card.Body>
										{showupdateForm && group.group_id === updateItemId ? (
											<>
												<Card.Title>
													<input
														type="text"
														name="group_name"
														value={updateItem ? updateItem.group_name : ''}
														onChange={handleUpdateFormChange}
													/>
												</Card.Title>
												<Card.Subtitle className="mb-2 text-muted">Group ID: {group.group_id}</Card.Subtitle>
												<Card.Text>Password: ........</Card.Text>
												<Button onClick={handleSaveClick}>Save</Button>
											</>
										) : (
											<>
												<Card.Title>{group.group_name}</Card.Title>
												<Card.Subtitle className="mb-2 text-muted">Group ID: {group.group_id}</Card.Subtitle>
												<Card.Text>Password: ........</Card.Text>
												<Button variant="primary" onClick={() => handleEdit(group, group.group_id)} style={{ marginRight: '8px' }}>Edit</Button>
												<Button variant="danger" onClick={() => handleDelete(group.group_id)}>Delete</Button>
											</>
										)}
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
				);
			case "Managers":
				return (
					<Row xs={1} md={3} className="g-4">
						{results.map((manager) => (
							<Col key={manager.manager_id}>
								<Card>
									<Card.Body>
										{showupdateForm && manager.manager_id === updateItemId ? (
											<>
												<Card.Title>
													<input
														type="text"
														name="manager_name"
														value={updateItem ? updateItem.manager_name : ''}
														onChange={handleUpdateFormChange}
													/>
												</Card.Title>
												<Card.Subtitle className="mb-2 text-muted">Manager ID: {manager.manager_id}</Card.Subtitle>
												<Button onClick={handleSaveClick}>Save</Button>
											</>
										) : (
											<>
												<Card.Title>Manager ID: {manager.manager_id}</Card.Title>
												<Card.Subtitle className="mb-2 text-muted">User ID: {manager.user_id}</Card.Subtitle>
												<Button variant="primary" onClick={() => handleEdit(manager, manager.manager_id)} style={{ marginRight: '8px' }}>Edit</Button>
												<Button variant="danger" onClick={() => handleDelete(manager.manager_id)}>Delete</Button>
											</>
										)}
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
				);
			default:
				return null;
		}
	};


	const showGroupModal = (recipeId) => {
		setSelectedRecipeId(recipeId);
		setShowModal(true);
	};

	const handleGroupSelect = (groupId) => {
		setSelectedGroupId(groupId);
		setShowModal(false);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleAddRecord = async (formData) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_HOST_API}/add`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ selectedTable: selectedFilter, formData }),
			});

			if (!response.ok) {
				throw new Error("Failed to add record");
			}

			setShowAddRecord(false);
			handleSearch();
		} catch (error) {
			console.error("Error adding record:", error);
		}
	};

	return (
		<div className="container mt-3">
			<div className="d-flex">
				<div className="col-md-4 me-2">
					<Form className="d-flex">
						<Form.Control
							type="search"
							placeholder="Search"
							className="me-2"
							aria-label="Search"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</Form>
				</div>
				<div className="col-md-4 pe-0 d-flex align-items-end">
					<Dropdown className="me-2">
						<Dropdown.Toggle variant="primary" id="dropdown-basic">
							{selectedFilter}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								onClick={() => {
									setSelectedFilter("Users");
									setResults([]);
								}}
							>
								Users
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									setSelectedFilter("Recipes");
									setResults([]);
								}}
							>
								Recipes
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									setSelectedFilter("Groups");
									setResults([]);
								}}
							>
								Groups
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									setSelectedFilter("Managers");
									setResults([]);
								}}
							>
								Managers
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<Button variant="outline-success" onClick={() => handleSearch(true)}>
						Search
					</Button>
				</div>
			</div>
			<div className="mt-3">{renderResultsTable()}</div>
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Select Group</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ListGroup>
						{groups &&
							groups.map((group) => (
								<ListGroup.Item
									key={group.group_id}
									action
									onClick={() => handleGroupSelect(group.group_id)}
								>
									{group.group_name}
								</ListGroup.Item>
							))}
					</ListGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						Close
					</Button>
					<Button
						variant="primary"
						onClick={() => handleAddRecipeToGroup(selectedRecipeId)}
					>
						Add to Group
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={detailModalShow} onHide={() => setDetailModalShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{detailTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{Array.isArray(detailContent) ? detailContent.join(', ') : detailContent}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setDetailModalShow(false)}>Close</Button>
				</Modal.Footer>
			</Modal>
			{showAddRecord && (
				<AddRecord
					show={showAddRecord}
					onClose={() => setShowAddRecord(false)}
					onSubmit={handleAddRecord}
					selectedTable={selectedFilter}
				/>
			)}
		</div>

	);
}

export default AdminPage;

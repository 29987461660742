import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "./authContext";

const url = process.env.REACT_APP_HOST_API;
const GroupContext = createContext();

export const useGroups = () => useContext(GroupContext);

export const GroupProvider = ({ children }) => {
	const [groups, setGroups] = useState([]);
	const { userProfile } = useAuth();

	const fetchGroups = async () => {
		if (userProfile) {
			let groupUrl = `${url}/groups`;
			if (userProfile.role !== "admin") {
				groupUrl += `/${userProfile.user_id}`;
			}
			const response = await fetch(groupUrl);
			if (response.ok) {
				const data = await response.json();
				setGroups(data.group);
			} else {
				console.error("Failed to fetch groups.");
			}
		} else {
			setGroups([]);
		}
	};

	useEffect(() => {
		fetchGroups();
	}, [userProfile]);

	const value = {
		groups,
		setGroups,
		fetchGroups,
	};

	return (
		<GroupContext.Provider value={value}>{children}</GroupContext.Provider>
	);
};
